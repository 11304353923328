


html, body {
  width: 100%;
  overflow-x: hidden; /* Ocultar el scroll horizontal */
  font-family: 'Mulish', sans-serif; 
}

* {
  box-sizing: border-box; /* Para asegurar que padding y borders no causen desbordamiento */
}



/* Navbar contenedor */
.navbar {
  background-color: #e4e6e3;
  margin: 0;
  display: flex;  /* Alinea los elementos en fila (row) */
  justify-content: space-evenly; /* Distribuye los elementos horizontalmente */
  align-items:end;
  width: 100%;
  
}
.logo-container {
  width: 20%; 
}

.logo-container img{
  width: 100%; 
}

/* Establecemos flexbox en el ul para alinear en fila */
.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0 1rem 1.2rem 1rem;
  display: flex;  /* Alinea los elementos en fila (row) */
  align-items: center; /* Alinea verticalmente los elementos en el centro */
  justify-content: space-around;
  width: 80%;
  
}

/* Cada item del navbar */
.navbar ul li {
  margin: 0 0.0625rem; /* Espaciado horizontal entre los elementos */

}


/* Enlaces del navbar */
.navbar ul li a {
  color: #515151;
  text-decoration: none;
  font-size: 2rem;
  display: block;
  position: relative; 

}
.navbar ul li a:hover {
  font-weight: bold; /* Cambia a negrita cuando pasas el mouse */
}


/* Estilo para el hover - línea morada debajo del enlace */
.navbar ul li a:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.130rem; /* Grosor de la línea */
  background-color: #9479fe; /* Color morado */
  border-radius: 0.3125rem;
  font-weight: bold; 
  transition: width 0.3s ease; /* Animación suave */
  
  
}

.navbar ul li a:hover, 
    .navbar ul li a.active {
      font-weight: bold; /* Aplica bold cuando se hace hover o está activo */
    }

/* Al principio la línea no aparece */
.navbar ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0; /* Ancho 0 para que no aparezca inicialmente */
  height: 0.125rem;
  background-color: #9479fe;
  transition: width 0.3s ease; /* Animación suave */
  
}

/* Al cargar la página, el enlace de "Home" tiene la línea por defecto */
.navbar ul li a.active::after {
  width: 100%; /* Muestra la línea morada completamente debajo de "Home" */
}

/* Estilos para el logo */
/*
nav ul li.logo {
  margin-right: auto; /* Alinea el logo a la izquierda */
/*}*/


.logo {
  /*height: 60px;*/ /* Ajusta el tamaño del logo */
  width: 40%;
  margin-right: 2rem;
 
}


/*Home*/

.home{
  display: flex;
  flex-direction: column;
  color:#515151;
  align-items: center;
  width: 100%;
  
}

.hello{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-around;
  padding: 2rem 1rem;
  width: 100%;
  height: 20rem;
  
  
  
  
}
.hello h1{
  font-family: 'League Spartan', sans-serif; 
  font-size: 7rem;
  margin:0;
  padding:0;
  letter-spacing: -0.4rem;
}


.bienvenidos{
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0;
  justify-content: center;
  width: 30%;
  height: 90%;
   
  
}
.bienvenidos h1{
  font-size: 6rem;
}
.bienvenidos h2{
  font-size: 2rem;
}
.mensajeHello{
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  width: 60%;
  text-align: justify;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.mensajeHello p{
  margin: 0;
  padding: 0;
  
  
  
}

.separadorMorado {
  border-left: 0.25rem solid #9479fe; /* Ancho y color de la línea */
  height: 80%; /* Altura de la línea */
  border-radius: 1rem ;
  width: 0.5rem;
  background-color: #9479fe;
  
  
}
.solucion{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: justify;
  text-align: justify;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  width: 70%;

}
.solucion p{
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}
.mensajeMoradoOvalo{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e4e6e3;
  background: #9479fe;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight:bold;
}
.mensajeMoradoOvalo p{
  margin: 0;
  padding: 0.4rem 0.5rem 0.4rem 0.5rem;

}

.como{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  
  
}
.serviciosHome{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  
}
.ovalPurple {
  width: 4.5rem;  /* Ajusta el ancho del óvalo */
  height: 2rem;  /* Ajusta la altura del óvalo */
  background-color: #9479fe;  /* Color morado del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: flex-end; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  padding-right: 0.3rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}

/* Estilo del círculo */
.circleGrey {
  width: 2rem;  /* Ancho del círculo */
  height: 2rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  background-color: #e4e6e3;  /* Color gris del círculo */
  border-radius: 50%;  /* Hace que el div sea un círculo */
}
.ovalGrey {
  width: 4.5rem;  /* Ajusta el ancho del óvalo */
  height: 2rem;  /* Ajusta la altura del óvalo */
  background-color: #e4e6e3;  /* Color morado del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: flex-end; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  padding-right: 0.3rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}

/* Estilo del círculo */
.circlePurple {
  width: 2rem;  /* Ancho del círculo */
  height: 2rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  background-color: #9479fe;  /* Color gris del círculo */
  border-radius: 50%;  /* Hace que el div sea un círculo */
}

.servicios1, .servicios2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.servicios2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}


.servicios1a, .servicios1b {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  
}

.servicios1a h2{
  margin-right: 1rem;
}
.servicios1a1, .servicios1a2, .servicios1b1, .servicios1b2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.mensajeMorado{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: #9479fe;
  width: 100%;
  height: 2rem;
 
  
}

.mensajeMorado h2{
  padding:0;
  margin:0;
  color:#e4e6e3

}
.elegirnos{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding: 1rem 0 0 0;
  
  
}
.fotoTodas {
  
  padding:0;
  margin: 0;
  width: 40%;

  
  
  
}
.fotoTodas img {
  margin: 0;
  padding: 0;
  max-width: 100%;
  
}

.mensajeEleccion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size:0.7rem;
  width: 50%;
  margin: 0;
  padding: 0;

}
.mensajeContacto{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
  
}
.mensajeContacto p{
  font-size: 1.2rem;
}


.textoEleccion p{
  padding: 0;
  margin:0;
  font-size:1.5rem;
  text-align: justify;
}

.btnGris{
  display: inline-block; 
  width: auto;  
  height: auto; 
  color: white;
  background: #515151;
  border-radius: 2rem;
  margin-top: 0.7rem;
  text-align: center;
  cursor: pointer; 
  padding: 0 0.5rem 0 0.5rem;

  
}

.btnGris a{
  font-size: 1.5rem;
  color: #e4e6e3;
  padding: 0.2rem;
  margin: 0;
  text-decoration: none;
  display: inline-block;     /* Ajusta el tamaño del enlace al contenido */
  white-space: nowrap; 
    
}

/*Equipo*/

.equipo{
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 3.5rem 3.5rem 0 3.5rem;
  
}

.titulo{
  width: 60%;
  margin-bottom: 2rem;
}
.titulo h2{

  align-items: left; 
  font-weight: bold;
  font-size: 2.5rem;
  padding:0;
  margin:0;
  letter-spacing: -0.07rem;
  

}
.introduccion{
  text-align: justify;
  width: 100%;
  margin-bottom: 1.8rem;
  letter-spacing: -0.07rem;
}
.introduccion p{
  padding:0;
  margin:0;
  font-size: 2rem;
  letter-spacing: -1px;

}
.conocenos{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}
.conocenos p{
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -1px;

}
.conocenosCadaUna{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 100%;

}
.naty, .cata{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 0;
  margin: 0;
}
.naty p, .cata p, .naty h2, .cata h2{
  padding: 0;
  margin: 0;

}
.naty p, .cata p{
  font-size: 1.5rem;
  letter-spacing: -1px;
  color: #515151;

}

.naty img, .cata img{
  width: 50%; /* Asegura que las imágenes ocupen el ancho completo de la pantalla */
}
.nombre-negro{
  color: #515151;
}
.nombre-morado{
  color:#9479fe
}
.natyDescripcion, .cataDescripcion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  padding-top: 1rem;

}


.natyDescripcion p, .cataDescripcion p {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  text-align: justify;

}
.mensajeContactoEquipo{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  
}
.mensajeContactoEquipo p{
  
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: -1px;
  padding: 1rem;
}
.mensajeSecundario{
  font-size: 1rem;
}
/* Servicios*/
.servicios{
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea los elementos a la izquierda */
  margin: 3rem;
}
.servicios h1{
  font-size: 3rem;
  width: 30%;
  letter-spacing: -1px;
  padding: 0;
  line-height: 2.7rem;
}
.servicios h2{
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: -1px;
}
.servicios ul li {
  font-size: 1.5rem;
  font-weight: 100; 
  color: #515151;
}

/*footbar*/

.footbar {
  display: flex; 
  flex-wrap: wrap;
  align-items: center; 
  justify-content: space-between; 
  background-color: #e4e6e3;
  text-align: left;
  font-weight: bold;
  padding: 1rem;
  margin: 0;
  width: 100%;
  
}

.footbar ul {
  list-style-type: none; 
  margin: 0;            
  padding: 0.5rem 0;         
}

.footbar ul li {        
  margin: 0;            
  position: relative;  
  padding: 0.15rem 0;  
}

.footbar ul li a {
  text-decoration: none; 
  color: #515151;       
  font-size: 1.5rem;    
  padding: 0;    
  display: inline-block; /* Cambia a inline-block */
  position: relative; /* Necesario para que el pseudo-elemento se posicione correctamente */
}

.footbar ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0; /* Inicialmente sin ancho */
  height: 0.125rem; /* Grosor de la línea */
  background-color: #515151; 
  transition: width 0.3s ease; 
}

.footbar ul li a:hover::after,
.footbar ul li a.active::after {
  width: 100%; /* Cambia a 100% para que ocupe el ancho del enlace */
  background-color: #515151; 
}

.footbar ul li a:hover,
.footbar ul li a.active {
  font-weight: bold; 
}

.footbar ul li a.active::after {
  width: 100%; 
  background-color: #515151; 

}


/* Estilo para el contenedor de información */
.footbar .info {
  display: flex;
  flex-direction: column; /* Alinea los elementos en columna dentro del div */
  
}

.menu{
  
  /*
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;*/
  flex:1;
  

}
.info{
  /*width: auto;*/
  flex: 1;
  text-align: center;
 
}
.info a {
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  color: #515151; /* Mantiene el color del texto según el contenedor */
  
  font-size: 1.3rem;
  font-weight: 300; 
}

.redes{
  /*
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;*/
  flex: 1;
  display: flex;
  justify-content: flex-end;

  
}

.redes img{
  /*width: 2.5rem;*/
  padding: 0.5rem;
  
}
.whatsapp img{
  width: 3rem;
  height: 3rem 
}
@media screen and (max-width: 700px) {
  /* Ajustes generales para pantallas más pequeñas */
  body {
    font-size: 1.2rem; /* Reduce el tamaño de la fuente en pantallas pequeñas */
  }
  /* Navbar contenedor */

/* Establecemos flexbox en el ul para alinear en fila */
.navbar ul {
  padding: 0 1rem 0.1rem 1rem;
}

/* Cada item del navbar */
.navbar ul li {
  margin: 0 0.03125rem; /* Espaciado horizontal entre los elementos */
  padding: 0.5rem;
}

/* Enlaces del navbar */
.navbar ul li a {
  font-size: 1.2rem;
}

.navbar img {
  /*height: 60px;*/ /* Ajusta el tamaño del logo */
  margin-right: 0;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

/*Home*/
.home{
  width: 100%;
  padding: 1rem;
  
}

.hello{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 15rem;
  width: 100%;
  
  
}
.hello h1{
  font-size: 2.5rem;
  letter-spacing: -0.15rem;
}
.bienvenidos{
  padding: 0;
  margin: 0;
  width: 100%;
  
  
}

.bienvenidos h2{
  font-size: 1.2rem;
}

.separadorMorado {
  border-radius: 1rem ;
  height: 0.25rem;
  background-color: #9479fe;
  width: 100%;
  margin: 0 0 0.5rem 0;

}
.mensajeHello{
  font-size: 1rem; 
  margin: 0;
  padding: 0;
  width: 100%;
}

.solucion{
  margin-bottom: 0.75rem;
  padding-top: 0;
  text-align: justify;
  width: 100%;

}
.solucion p{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.mensajeMoradoOvalo{
  display: inline-block; 
  width: auto;  
  padding: 0;
  border-radius: 2rem;
}
.mensajeMoradoOvalo p{ 
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  font-size: 1rem;
  font-weight:bold;
}

.como h3{
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem;

}
.serviciosHome{
  padding-bottom: 0.1rem;
  width:100%;
  padding: 0;
  
}
.ovalPurple, .ovalGrey {
  width: 2rem;  /* Ajusta el ancho del óvalo */
  height: 1rem;  /* Ajusta la altura del óvalo */
  border-radius: 1.5rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  padding-right: 0.15rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.25rem;
}

/* Estilo del círculo */
.circleGrey, .circlePurple {
  width: 1rem;  /* Ancho del círculo */
  height: 1rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
}

.servicios1a{
  
  width: 60%;
  margin-left: 0.5rem;
  
}


.servicios1a1 h2, .servicios1a2 h2, .servicios1b1 h2, .servicios1b2 h2, .servicios2 h2{
  
  font-size: 1rem;
  width: 100%;
  
}

.mensajeMorado{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: #9479fe;
  width: 100%;
  height: auto;
}
.mensajeMorado h2{
  font-size: 1rem;
}

.elegirnos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;  
  
}
.fotoTodas {
  
  padding:0;
  margin: 0;
  width: 40%;
  
}
.fotoTodas img {
  margin: 0;
  padding: 0;
  max-width: 100%;
  
}

.mensajeEleccion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;

}
.mensajeContacto{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;

  
}
.mensajeContacto p{
  font-size: 0.7rem;
}
.textoEleccion{
  width: 100%;
  align-items:center;
  text-align: justify;
}

.textoEleccion p{
  padding: 0;
  margin:0;
  text-align: justify;
  font-size: 1rem;
}

.btnGris{
  display: inline-block; 
  width: auto;   
  color: white;
  background: #515151;
  border-radius: 1rem;
  margin: 0.35rem 0.35rem;
  text-align: center;
  cursor: pointer; 
  padding: 0 0.25rem 0 0.25rem;
  
  
}

.btnGris a{
  font-size: 1rem;
  padding: 0.1rem;
    
}

/*Equipo*/

.equipo{

  margin: 1rem;
  padding: 0;
  width: 90%;
  
}

.titulo{
  padding: 0;
  margin:0;
  width: 100%;
}
.titulo h2{

  font-size: 1.3rem;
  letter-spacing: -0.05rem;
  margin-bottom: 0.5rem;

}
.introduccion{
  
  width: 100%;
  margin-bottom: 0.5rem;
}
.introduccion p{
  font-size: 1rem;
  letter-spacing: -0.05rem;

}
.conocenos p{
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: -0.1rem;

}
.conocenosCadaUna{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  

}

.naty, .cata{
  width: 50%;
  padding: 0;
  margin: 0;

}

.naty p, .cata p{
  font-size: 1rem;
  letter-spacing: -0.1rem;

}

.naty img, .cata img{
  width: 70%; /* Asegura que las imágenes ocupen el ancho completo de la pantalla */
  
}
.naty h2, .cata h2{
  font-size: 1rem;
}

.natyDescripcion, .cataDescripcion {
  display: flex;
  flex-direction: column;
  align-items: justify;
  width: 95%;
  padding-top: 1rem;
}


.natyDescripcion p, .cataDescripcion p {
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  text-align: justify;
  letter-spacing: -0.05rem;

}
.mensajeContactoEquipo{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  
}
.mensajeContactoEquipo p{

  font-size: 0.9rem;
  letter-spacing: -0.05rem;
  padding: 0;
}
.mensajeSecundario{
  font-size: 0.55rem;
}

/* Servicios*/
.servicios{
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea los elementos a la izquierda */
  margin: 1rem;
}
.servicios h1{
  font-size: 1.5rem;
  width: 100%;
  letter-spacing: -0.1rem;
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
}
.servicios h2{
  font-size: 1.1rem;
  letter-spacing: -0.03rem;
  width: 100%;
  
  
}
.servicios ul{
  margin: 0 0.5rem 0 1rem;
  padding: 0;
  
}
.servicios ul li {
  font-size: 0.9rem;
  
}

.footbar {
  display: flex; /* Habilita flexbox */
  justify-content: space-between; /* Espacia los elementos en la fila */
  flex-wrap: wrap;
}



.redes {
  flex-basis: 100%; /* Hace que Elemento 3 ocupe toda la línea */
  text-align: center; 
  align-items: center;
  justify-content: center;
}



.footbar ul {
  list-style-type: none; 
  margin: 0;            
  padding: 0.5rem 0;         
}

.footbar ul li {        
  margin: 0;            
  position: relative;  
  padding: 0;  
  height: 1.4rem;
  
  
  
}

.footbar ul li a {
  text-decoration: none; 
  color: #515151;       
  font-size: 0.9rem;    
  padding: 0; 
  margin:0;   
  display: inline-block; /* Cambia a inline-block */
  position: relative; /* Necesario para que el pseudo-elemento se posicione correctamente */
  
}

.footbar ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0; /* Inicialmente sin ancho */
  height: 0.125rem; /* Grosor de la línea */
  background-color: #515151; 
  transition: width 0.3s ease; 
}

.footbar ul li a:hover::after,
.footbar ul li a.active::after {
  width: 100%; /* Cambia a 100% para que ocupe el ancho del enlace */
  background-color: #515151; 
}

.footbar ul li a:hover,
.footbar ul li a.active {
  font-weight: bold; 
}

.footbar ul li a.active::after {
  width: 100%; 
  background-color: #515151; 
}

/*
.menu{
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}
.info{
  width: auto;
}*/
.info a {
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  color: #515151; /* Mantiene el color del texto según el contenedor */
  font-size: 0.9rem;
  font-weight: 300; 
}
/*
.redes{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  
}*/

.redes img{
  width: 2.3rem;
  
  
}
.whatsapp img{
  width: 1.7rem;
  height: 1.7rem 
}


  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
